<template>
  <div class="libros-container">
    <div class="description-section">
      <h1>Libros de Bolsa</h1>
      <p>En Eurekers ponemos a tu disposición las herramientas necesarias para convertirse en su propio asesor financiero. En esta página encontrarás información sobre impuestos e información sobre nuestros alumnos auditados de este curso, que han tenido éxito dando sus primeros pasos en el mundo de las inversiones con el método Eurekers, un método fácil y lógico para invertir a largo plazo. Las bases de este método son tres: localizar las mejores compañías en las que invertir, controlar el riesgo de cada una de las operaciones y, por último, controlar las emociones. Descárgate ahora nuestros libros de bolsa y aprende a invertir a través del método Eurekers.</p>
    </div>
    
    <div class="books-grid">
      <div 
        v-for="(book, index) in books" 
        :key="index" 
        class="book-card"
        @click="navigateToBook(book.link)"
      >
        <div class="book-image">
          <img :src="book.image" :alt="book.title">
        </div>
        <div class="book-title">
          <h3>{{ book.title }}</h3>
        </div>
      </div>
    </div>

    <!-- Subscription Modal -->
    <div v-if="showSubscriptionModal" class="subscription-modal-overlay">
      <div class="subscription-modal">
        <div class="modal-header">
          <h2>Suscríbete para acceder</h2>
          <button class="close-button" @click="closeModal"><span class="material-icons">close</span></button>
        </div>
        <div class="modal-body">
          <p>Para acceder a nuestros libros gratuitos, por favor suscríbete a nuestro newsletter.</p>
          
          <form @submit.prevent="submitSubscription">
            <div class="form-group">
              <label for="name">Nombre*</label>
              <input 
                type="text" 
                id="name" 
                v-model="subscriptionForm.name" 
                required
                :class="{ 'invalid': formErrors.name }"
              >
              <span v-if="formErrors.name" class="error-message">{{ formErrors.name }}</span>
            </div>
            
            <div class="form-group">
              <label for="surname">Apellidos*</label>
              <input 
                type="text" 
                id="surname" 
                v-model="subscriptionForm.surname" 
                required
                :class="{ 'invalid': formErrors.surname }"
              >
              <span v-if="formErrors.surname" class="error-message">{{ formErrors.surname }}</span>
            </div>
            
            <div class="form-group">
              <label for="email">Email*</label>
              <input 
                type="email" 
                id="email" 
                v-model="subscriptionForm.email" 
                required
                :class="{ 'invalid': formErrors.email }"
              >
              <span v-if="formErrors.email" class="error-message">{{ formErrors.email }}</span>
            </div>
            
            <div class="form-group">
              <label for="phone">Teléfono (opcional)</label>
              <input 
                type="tel" 
                id="phone" 
                v-model="subscriptionForm.phone"
              >
            </div>
            
            <div class="form-group checkbox">
              <input 
                type="checkbox" 
                id="mandated" 
                v-model="subscriptionForm.mandated" 
                required
                :class="{ 'invalid': formErrors.mandated }"
              >
              <label for="mandated">Acepto recibir comunicaciones sobre noticias, eventos y ofertas de Eurekers*</label>
              <span v-if="formErrors.mandated" class="error-message">{{ formErrors.mandated }}</span>
            </div>
            
            <div class="form-actions">
              <button type="submit" class="submit-button" :disabled="isSubmitting">
                {{ isSubmitting ? 'Enviando...' : 'Suscribirse y descargar' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  name: 'LibrosBolsa',
  mixins: [APICaller],
  data() {
    return {
      books: [
        {
          title: 'Revista Eurekers: Enero 2025',
          image: 'https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/revista/2025/Revista_2025_WEB_page-0001.jpg',
          link: 'https://www.eurekers.com/eurekers-magazine/2025'
        },
        {
          title: 'Revista Eurekers: Enero 2024  ',
          image: 'https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/revista/2024/Revista_2024_WEB_page-0001.jpg',
          link: 'https://www.eurekers.com/eurekers-magazine/2024'
        },
        {
          title: 'Revista Eurekers: Enero 2023  ',
          image: 'https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/revista/2023/Revista_2023_WEB_page-0001.jpg',
          link: 'https://www.eurekers.com/eurekers-magazine/2023'
        },
        {
          title: 'Revista Eurekers: Enero 2022',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/revista2022.jpg?itok=vl4xxKIa',
          link: 'https://www.eurekers.com/eurekers-magazine/2022'
        },
        {
          title: 'Revista Eurekers: Enero 2021 ',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/revista2021.jpg?itok=gkkjIsGU',
          link: 'https://www.eurekers.com/eurekers-magazine/2021'
        },
        {
          title: 'Revista Eurekers: Enero 2020',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/revista2020.jpg?itok=Eqsc4Ozf',
          link: 'https://www.eurekers.com/eurekers-magazine/2020'
        },
        {
          title: 'Revista Eurekers: Enero 2019',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/revista2019.jpg?itok=ri2txsPw',
          link: 'https://www.eurekers.com/eurekers-magazine/2019'
        },
        {
          title: 'Un Monje en Wall Street',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/monje-en-wall-street.jpg?itok=y3p6sx5g',
          link: 'https://s3-eu-west-1.amazonaws.com/assets.eurekers.com/revista/monje-digital-reducido.pdf'
        },
        {
          title: 'La bolsa como inversión rentable y como hobby',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_luisferrer.jpg?itok=x6-gpTzc',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/la-bolsa-como-inversion-rentable-y-como-hobbie.pdf'
        },
        {
          title: 'Cambio de Mentalidad a la hora de invertir. Mujeres rompiendo clichés.',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/fatima-zamorano_0.png?itok=Q-_g-PTY',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/03/fatima-zamorano-experiencia-eurekers.pdf'
        },
        {
          title: 'Eduardo Andrés, ganador del World Top Investor 2017',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_eduardoandres.jpg?itok=C7yeQUxE',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/eduardo-andres-world-top-investor-con-el-m%C3%A9todo-eurekers.pdf'
        },
        {
          title: '¿Cuánto dinero necesito para invertir en bolsa?',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/consejo_dinero.jpg?itok=yQ4aQC7l',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/09/cuanto-dinero-necesito-para-invertir-en-bolsa.pdf'
        },
        {
          title: '10 consejos para elegir broker',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/consejo_broker.jpg?itok=MhMBg1cA',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/diez-consejos-para-elegir-broker.pdf'
        },
        {
          title: '4 claves para invertir en bolsa sin ser experto',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/consejo_4claves.jpg?itok=HP0JTQbG',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/4-claves-para-invertir-en-bolsa.pdf'
        },
        {
          title: 'Aplicando la constancia en la inversión en bolsa',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_asierlana.jpg?itok=j3DSw88V',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/aplicando-la-constancia-a-la-inversion-en-bolsa.pdf'
        },
        {
          title: 'Libertad financiera, la máxima de cualquier inversor',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_joseandujar.jpg?itok=almFkL4I',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/la-libertad-financiera-ha-de-ser-la-maxima-de-cualquier-inversor.pdf'
        },
        {
          title: 'Huir del ruido bursátil es sano. Caso de éxito Teo.',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_teogalan.jpg?itok=IpZUntHe',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/huir-del-ruido-bursatil-es-saludable.pdf'
        },
        {
          title: 'Aprende cómo y dónde invertir en bolsa',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/exito_miguelmartinez.jpg?itok=PVDKvx2L',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/aprende-como-y-donde-invertir-en-bolsa-con-miguel-martinez.pdf'
        },
        {
          title: '¿Cómo tributan los dividendos obtenidos fuera de España?',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/guia_fiscalidad.jpg?itok=j7Qk0YtT',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/como-tributan-dividendos-fuera-espana.pdf'
        },
        {
          title: 'Guía de tributación 2019: fiscalidad de las acciones y los dividendos',
          image: 'http://statictthh.s3.amazonaws.com/styles/large/s3/field/image/guia_tributacion.jpg?itok=VXUMZ8on',
          link: 'https://cursodebolsa.eurekers.com/wp-content/uploads/2019/10/guia-de-tributacion-2019.pdf'
        },
      ],
      showSubscriptionModal: false,
      pendingBookLink: null,
      isSubmitting: false,
      subscriptionForm: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        mandated: false
      },
      formErrors: {
        name: '',
        surname: '',
        email: '',
        mandated: ''
      }
    }
  },
  computed: {
    ...mapGetters(['_g_IsUserLogged'])
  },
  methods: {
    navigateToBook(link) {
      if (this._g_IsUserLogged || localStorage.getItem('hasSubscribedToNewsletter')) {
        window.open(link, '_blank');
      } else {
        this.pendingBookLink = link;
        this.showSubscriptionModal = true;
      }
    },
    
    closeModal() {
      this.showSubscriptionModal = false;
      this.pendingBookLink = null;
      this.resetForm();
    },
    
    resetForm() {
      this.subscriptionForm = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        mandated: false
      };
      this.formErrors = {
        name: '',
        surname: '',
        email: '',
        mandated: ''
      };
      this.isSubmitting = false;
    },
    
    validateForm() {
      let isValid = true;
      this.formErrors = {
        name: '',
        surname: '',
        email: '',
        mandated: ''
      };
      
      if (!this.subscriptionForm.name.trim()) {
        this.formErrors.name = 'El nombre es obligatorio';
        isValid = false;
      }
      
      if (!this.subscriptionForm.surname.trim()) {
        this.formErrors.surname = 'Los apellidos son obligatorios';
        isValid = false;
      }
      
      if (!this.subscriptionForm.email.trim()) {
        this.formErrors.email = 'El email es obligatorio';
        isValid = false;
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.subscriptionForm.email)) {
          this.formErrors.email = 'Por favor, introduce un email válido';
          isValid = false;
        }
      }
      
      if (!this.subscriptionForm.mandated) {
        this.formErrors.mandated = 'Debes aceptar recibir comunicaciones';
        isValid = false;
      }
      
      return isValid;
    },
    
    submitSubscription() {
      if (!this.validateForm()) {
        return;
      }
      
      this.isSubmitting = true;

      const params = new URLSearchParams();
      params.append('name', this.subscriptionForm.name);
      params.append('surname', this.subscriptionForm.surname);
      params.append('email', this.subscriptionForm.email);
      params.append('mandated', this.subscriptionForm.mandated);
      if (this.subscriptionForm.phone) {
        params.append('phone', this.subscriptionForm.phone);
      }
      
      const success = response => {
        if (response.data.success) {
          localStorage.setItem('hasSubscribedToNewsletter', 'true');
          
          this.closeModal();
          
          if (this.pendingBookLink) {
            window.open(this.pendingBookLink, '_blank');
            this.pendingBookLink = null;
          }
          
          this.$vueOnToast.pop('success', 'Suscripción exitosa', 'Gracias por suscribirte a nuestro newsletter.');
        } else {
          this.$vueOnToast.pop('error', 'Error en la suscripción', response.data.error || 'Por favor, verifica los datos introducidos.');
        }
        this.isSubmitting = false;
      };
      
      const apiUrl = '/api/suscribir-informe';
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('401', 'Error al procesar la suscripción. Inténtalo de nuevo más tarde.', () => {
        this.isSubmitting = false;
      });
      
      this._postAPICall(apiUrl, params, successHandler, failureHandler);
    }
  }
}
</script>

<style scoped>
.libros-container {
  padding: 2rem;
  color: var(--color-text-primary);
}

.description-section {
  margin-bottom: 3rem;
  max-width: 1200px;
}

.description-section h1 {
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.description-section p {
  color: var(--color-text-secondary);
  line-height: 1.6;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.book-card {
  background-color: var(--color-surface);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 1px solid var(--color-outline);
}

.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: var(--color-active-surface);
}

.book-image {
  height: 300px;
  overflow: hidden;
}

.book-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-title {
  padding: 1rem;
}

.book-title h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.subscription-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.subscription-modal {
  background-color: var(--color-surface);
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid var(--color-outline);
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--color-text-primary);
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: -0.5rem;
}

.close-button:hover {
  color: var(--color-text-primary);
}

.modal-body {
  padding: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.75rem;
  color: var(--color-text-primary);
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"] {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-outline);
  border-radius: 4px;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  box-sizing: border-box;
}

.form-group input.invalid {
  border-color: var(--color-error);
}

.form-group.checkbox {
  display: flex;
  align-items: flex-start;
  margin-top: 1.75rem;
}

.form-group.checkbox input {
  margin-top: 0.3rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.form-group.checkbox label {
  margin-bottom: 0;
  line-height: 1.4;
}

.error-message {
  display: block;
  color: var(--color-error);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.form-actions {
  margin-top: 2.5rem;
  text-align: center;
  padding-bottom: 0.5rem;
}

.submit-button {
  background-color: var(--color-accent-primary);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.85rem 2.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: var(--color-accent-700);
}

.submit-button:disabled {
  background-color: var(--color-muted);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .book-image {
    height: 250px;
  }
  
  .subscription-modal {
    width: 95%;
    max-height: 80vh;
  }
  
  .modal-body {
    padding: 1.5rem;
  }
}
</style> 